<template>
  <div>
    <el-dialog
      :title="title == '新增' ? '新增用户' : '修改用户密码'"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form
        :model="userFrom"
        :rules="rules"
        ref="userFrom"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户账号" prop="userAccount">
          <el-input
            v-model="userFrom.userAccount"
            :disabled="title == '修改'"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input
            v-model="userFrom.userName"
            :disabled="title == '修改'"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input disabled v-model="userFrom.userId"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input v-model="userFrom.password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colse">取 消</el-button>
        <el-button type="primary" @click="submitForm('userFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      dialogVisible: false,
      userFrom: {
        userId: '',
        userName: '',
        userAccount: '',
        password: '',
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        userAccount: [
          { required: true, message: '请输入用户账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
        ],
      },
      isimg: false,
      title: '新增',
    }
  },

  created() {},

  mounted() {},

  methods: {
    init(val, row) {
      this.title = val
      this.dialogVisible = true
      if (val == '新增') {
        this.userFrom = {}
      } else {
        this.userFrom = JSON.parse(JSON.stringify(row))
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    colse() {
      this.userFrom = {
        userId: '',
        userName: '',
        userAccount: '',
        password: '',
      }
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.userFrom.userId) {
            let params = {
              // code: "",
              // data: {
              userId: this.userFrom.userId,
              userName: this.userFrom.userName,
              userAccount: this.userFrom.userAccount,
              password: this.userFrom.password,

              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/User/addUserAccount',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          } else {
            let params = {
              // code: "",
              // data: {
              userId: this.userFrom.userId,
              userName: this.userFrom.userName,
              userAccount: this.userFrom.userAccount,
              password: this.userFrom.password,

              // },
              // time: "",
            }
            let { data } = await this.$base.postAjax(
              '/user/User/updateUserPassword',
              params
            )
            if (data.code == 200) {
              this.$message.success('成功')
              this.dialogVisible = false
              this.$emit('onSubmit')
            }
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dif {
  display: flex;
}
.attachmentL {
  position: relative;
  margin-right: 10px;
}
.imgwh {
  width: 80px;
  height: 80px;
}
.mask {
  position: absolute;
  right: 7px;
  top: 3px;
  .el-icon-delete {
    font-size: 20px;
    cursor: pointer;
  }
}
.addbox {
  border: 1px dashed #d9d9d9;
  width: 80px;
  height: 80px;
}
.avatar-uploader-icon,
el-icon-delete {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
