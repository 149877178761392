<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>用户管理</span>
      </div>
      <el-form :inline="true" :model="searchForm" ref="searchRef">
        <el-form-item label="用户姓名" prop="userName">
          <el-input
            v-model="searchForm.userName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户性别" prop="sex">
          <el-select v-model="searchForm.sex" placeholder="请选择" clearable>
            <el-option label="男" :value="1"> </el-option>
            <el-option label="女" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户手机号" prop="phoneNumber">
          <el-input
            v-model="searchForm.phoneNumber"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" @click="resetSearch('searchRef')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div class="form-box">
        <el-button plain type="primary" icon="el-icon-plus" @click="addMan"
          >新增</el-button
        >
        <!-- <el-button icon="el-icon-download" plain type="primary" @click="onBatchExport"
          >导出</el-button
        > -->
        <!-- <el-button icon="el-icon-upload2" plain type="primary" @click="add"
          >导入</el-button
        > -->
      </div>
    </el-card>
    <el-card shadow="never" class="data-list">
      <div slot="header" class="clearfix">
        <span>用户列表</span>
      </div>
      <div class="table-box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55" fixed> </el-table-column> -->
          <!-- <el-table-column prop="headImage" label="用户头像" width="90" fixed>
            <template slot-scope="scope">
              <img
                class="bgimg"
                :src="scope.row.userPhoto ? scope.row.userPhoto : img1"
                alt=""
              />
            </template>
          </el-table-column> -->
          <el-table-column prop="userId" label="用户ID"> </el-table-column>
          <el-table-column prop="userAccount" label="用户账号">
          </el-table-column>
          <el-table-column prop="userName" label="用户姓名"> </el-table-column>
          <!-- <el-table-column prop="userSex" label="用户性别">
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.userSex == 1 ? "男" : scope.row.userSex == 0 ? "女" : "未知"
                }}
              </span>
            </template>
          </el-table-column> -->
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editUser(scope.row)"
                >修改密码</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="bangdingrole(scope.row)"
                >绑定角色</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="userIdCard" label="用户身份证" min-width="130">
          </el-table-column> -->
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="total < pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[6, 12, 24, 36, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!--  -->
    <addUser ref="adduser" @onSubmit="onSubmit" />
    <el-dialog title="绑定角色" :visible.sync="dialogVisible" width="450px">
      <el-select v-model="roleId" placeholder="角色">
        <el-option
          v-for="item in roleDataoption"
          :key="item.id"
          :label="item.roleName"
          :value="item.id + ''"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colse">取 消</el-button>
        <el-button type="primary" @click="bangdingChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import addUser from './adduser'
import table2excel from 'js-table2excel'
import * as userApi from '@/api/user'
export default {
  data() {
    return {
      searchForm: {
        userName: null,
      },
      currentPage: 1,
      pageSize: 6,
      total: null,
      input: '',
      tableData: [],

      img1: require('/src/assets/images/user.png'),
      dialogVisible: false,
      roleId: '',
      rowForm: {},
      roleDataoption: [],
    }
  },
  components: {
    addUser,
  },
  methods: {
    addMan() {
      // this.showuser = true;
      // this.$nextTick(() => {
      this.$refs.adduser.init('新增')
      // });
    },
    editUser(val) {
      this.$refs.adduser.init('修改', val)
    },

    //支持导出图片和类型转换
    async onBatchExport() {
      let params = {
        data: {
          ...this.searchForm,
        },
        pageNum: 1,
        pageSize: this.total,
      }
      let res = await userApi.userList(params)
      if (res.code == 200) {
        let tableLists = res.data.records

        tableLists.map((item) => {
          item.userSex =
            item.userSex == 1 ? '男' : item.userSex == 2 ? '女' : '未知'
        })
        const column = [
          {
            title: '用户头像',
            key: 'userPhoto',
            type: 'image',
            width: 50,
            height: 50,
          },
          { title: '用户姓名', key: 'userName', type: 'text' },
          { title: '用户性别', key: 'userSex', type: 'text' },
          { title: '用户手机号', key: 'userPhone', type: 'text' },
          { title: '用户身份证号', key: 'userIdCard', type: 'text' },
        ]
        table2excel(column, tableLists, '用户信息')
      }
    },
    // async onDelete(id) {
    //   let params = {
    //     code: "",
    //     data: id,
    //     time: "",
    //   };
    //   let { data } = await this.$base.postAjax(
    //     "/mallUser/CustomerMan/deleteCustomerS",
    //     params
    //   );
    //   if (data.code == 200) {
    //     this.$message.success("删除成功");
    //     this.onSubmit();
    //   }
    // },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData()
    },
    //重置
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.onSubmit()
    },
    //搜索
    onSubmit() {
      this.currentPage = 1
      this.getData()
    },
    //获取数据
    async getData() {
      let params = {
        data: {
          ...this.searchForm,
        },
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }
      let res = await userApi.userList(params)
      if (res.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.total
      } else {
        this.$message.warning(res.msg)
      }
    },
    //监听排序
    sortChange() {},
    //表格数据
    ValueFormatter(a, b, c) {
      if (c && c != null && c != undefined && c != 'null' && c != 'undefined') {
        return c
      } else {
        return '-'
      }
    },
    bangdingrole(row) {
      // this.roleId = row.roleId || ''
      this.rowForm = row
      this.dialogVisible = true
      let params = {
        data: {
          pageNum: 1,
          pageSize: 999,
        },
      }
      userApi.roleList(params).then((res) => {
        this.roleDataoption = res.data.data
      })
    },
    colse() {
      this.roleId = ''
      this.dialogVisible = false
    },
    bangdingChange() {
      if (this.roleId) {
        userApi
          .addUserRoleBindin({
            roleId: this.roleId,
            userAccount: this.rowForm.userAccount,
            userId: this.rowForm.userId
          })
          .then((res) => {
            this.onSubmit()
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.dialogVisible = false
          })
      } else {
        this.$message.error('请选择角色！')
      }
    },
  },
  created() {
    this.onSubmit()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
